import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Schedule from "../components/images/date.jpg"

const DatesPage = () => (
  <Layout>
    <SEO title="Dates" />
    <Banner title="Dates"></Banner>

    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">

          <div className="col-md-12">
            <div className="row clearfix">
              <div className="mb-0">
                <div className="heading-block" style={{paddingTop: '40px'}}>
                  <img
                    src={Schedule}
                    className="bor-tr mt-3 mb-3"
                    alt="SCHEDULE"
                  />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    

  </Layout>
)

export default DatesPage
